const originalFetch = window.fetch;

window.fetch = async (input, init = {}) => {
  // Get token from localStorage, sessionStorage, or state management
  let token = localStorage.getItem("user_token");
  let apiKey = localStorage.getItem("user_apiKey");
  
  // Ensure headers exist
  init.headers = {
    ...init.headers,
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  // ✅ Move credentials OUTSIDE of headers
  init.credentials = "include";

  try {
    let response = await originalFetch(input, init);
     console.log('resp refresh',response)
    // 🔄 If access is forbidden, try to refresh the token
    if (response.status === 401) {
      console.warn("Access forbidden, refreshing token...");

      const refreshResponse = await originalFetch("https://api.payhub.link/user/refresh", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          apiKey: apiKey,
        },
      });
      

      if (refreshResponse.ok) {
        const data = await refreshResponse.json();
        console.log("New Token Received:", data.accessToken);

        // ✅ Store the new token
        localStorage.setItem("user_token", data.accessToken);

        // ✅ Retry the original request with the new token
        init.headers.Authorization = `Bearer ${data.accessToken}`;
        response = await originalFetch(input, init);
      } else {
        console.error("Failed to refresh token, logging out...");
        localStorage.removeItem("user_token"); // Clear token
        window.location.href = "/login"; // Redirect to login
        return refreshResponse;
      }
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
